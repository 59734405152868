import React, {FC} from "react";
import {convertStyles} from "../../../services/utils"

interface IProps {
    item: any
}

const Heading: FC<IProps> = ({item}) => {
    let tagEntry = `<${item.tag}>${item.headingText}</${item.tag}>`;
    const setStyles = item.settings ? convertStyles(item.settings) : {};
    return (
        <>
            {/*@ts-ignore*/}
            <div
                //@ts-ignore
                data-aos={setStyles?.animationAos['data-aos']}
                //@ts-ignore
                style={{width: "100%", ...setStyles.parentStyles}}
            >
                <div
                    //@ts-ignore
                    style={{...setStyles.styles}}
                    dangerouslySetInnerHTML={{
                        __html: tagEntry,
                    }}
                />
            </div>
        </>
    );
}

export default Heading;
